import React from 'react';
import { motion } from 'framer-motion';
import HoverScale from '../components/HoverScale';
import SEO from '../components/SEO';
import Partners from '../components/Partners';

const Home: React.FC = () => {
  const services = [
    {
      title: 'DevOps Cloud Consultancy',
      description: 'Expert guidance for your cloud transformation journey'
    },
    {
      title: 'Cloud Migration',
      description: 'Seamless transition to cloud infrastructure'
    },
    {
      title: 'Infrastructure Management',
      description: 'Reliable and scalable infrastructure solutions'
    },
    {
      title: 'MLOps Implementation',
      description: 'End-to-end machine learning operations'
    },
    {
      title: 'Cloud Security',
      description: 'Comprehensive cloud security solutions'
    },
    {
      title: 'Cloud Cost Optimization',
      description: 'Maximize cloud ROI with efficient resource management'
    }
  ];

  const seoServices = services.map(service => ({
    name: service.title,
    description: service.description,
    provider: 'ACEDEMAND IT Consulting',
    serviceType: 'Cloud Services',
    areaServed: 'Worldwide'
  }));

  return (
    <>
      <SEO
        title="ACEDEMAND - Cloud & DevOps Solutions | listen. think. solve."
        description="Transform your business with our expert cloud consulting and DevOps solutions. We specialize in cloud migration, infrastructure management, MLOps, and security services."
        keywords="cloud consulting, DevOps solutions, cloud migration, infrastructure management, MLOps, cloud security, IT consulting, digital transformation, Google Cloud Platform, DevOps automation"
        services={seoServices}
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        {/* Hero Section */}
        <section id="hero" className="min-h-[70vh] flex flex-col items-center justify-center text-center px-4">
          <motion.h1 
            className="text-6xl font-bold text-primary mb-8 tracking-wider"
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            <span className="text-primary">listen.</span>
            {' '}
            <span className="text-secondary">think.</span>
            {' '}
            <span className="text-primary">solve.</span>
          </motion.h1>

          <motion.p 
            className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            We believe that success of our clients is success of our own.
            Thus we make sure that they succeed by collaborating with them.
          </motion.p>
        </section>

        {/* Decorative Stripe */}
        <div className="h-16 bg-gradient-to-r from-primary to-secondary" />

        {/* Services Section */}
        <section id="services" className="py-20 px-4 bg-white">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16 text-primary">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <HoverScale key={index}>
                  <motion.div
                    className="p-8 rounded-xl bg-white shadow-lg border border-gray-100 h-full flex flex-col"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <h3 className="text-xl font-semibold mb-3 text-primary">{service.title}</h3>
                    <p className="text-gray-600 flex-grow">{service.description}</p>
                  </motion.div>
                </HoverScale>
              ))}
            </div>
          </div>
        </section>

        {/* Partners Section */}
        <Partners />

        {/* CTA Section */}
        <section className="py-20 px-4 bg-gradient-to-r from-primary to-secondary text-white">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Business?</h2>
            <p className="text-lg mb-8 opacity-90">
              Let's collaborate to build a robust cloud infrastructure that drives your success.
            </p>
            <a
              href="#contact"
              className="inline-flex items-center px-8 py-4 border-2 border-white rounded-md text-lg font-medium hover:bg-white hover:text-primary transition-colors duration-200"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Get Started Today
              <span className="ml-2">→</span>
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home; 