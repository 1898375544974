import React from 'react';
import { motion } from 'framer-motion';

const partners = [
  {
    name: 'Sonatype',
    logo: '/partners/Sonatype_logo_full_color.webp',
    alt: 'Sonatype Logo',
    darkModeInvert: true,
    showCard: true
  },
  {
    name: 'Google Cloud Platform',
    logo: '/partners/Premier-Partner-de-Google-Cloud.png',
    alt: 'Google Cloud Premier Partner Logo',
    darkModeInvert: false,
    showCard: false
  },
  {
    name: 'Palo Alto Networks',
    logo: '/partners/PANW_Parent_Brand_Primary_Logo_RGB.png',
    alt: 'Palo Alto Networks Logo',
    darkModeInvert: true,
    showCard: true
  }
];

const Partners: React.FC = () => {
  return (
    <section className="bg-dark-surface text-dark-text py-12">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-3xl font-bold text-center mb-12 text-white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Our Trusted Partners
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center justify-items-center">
          {partners.map((partner, index) => (
            <motion.div
              key={partner.name}
              className={`w-full max-w-[280px] flex items-center justify-center ${
                partner.showCard ? 'p-8 bg-gray-900 rounded-lg shadow-sm hover:shadow-md' : 'p-4'
              } transition-shadow duration-300`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <img
                src={partner.logo}
                alt={partner.alt}
                className={`w-full h-auto object-contain ${partner.darkModeInvert ? 'brightness-0 invert' : ''}`}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners; 