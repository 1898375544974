import React from 'react';
import { motion } from 'framer-motion';

interface HoverScaleProps {
  children: React.ReactNode;
  isCard?: boolean;
}

const HoverScale: React.FC<HoverScaleProps> = ({ children, isCard = false }) => {
  const cardVariants = {
    hover: { 
      scale: 1.02,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    tap: {
      scale: 0.98,
      transition: {
        duration: 0.1,
        ease: "easeInOut"
      }
    }
  };

  const defaultVariants = {
    hover: { 
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 300
      }
    },
    tap: { 
      scale: 0.95
    }
  };

  return (
    <motion.div
      whileHover="hover"
      whileTap="tap"
      variants={isCard ? cardVariants : defaultVariants}
    >
      {children}
    </motion.div>
  );
};

export default HoverScale; 